<template>
    <div class="document">
        <div class="title1">
            Ferran Ruiz Sala
        </div>
        <div class="job">
            Game Developer
        </div>
        <div class="location">
            Barcelona, Spain
        </div>

        <div class="title2-row">
            <div class="icon">person</div>
            Profile
        </div>

        <div class="leftpad">

            <div class="text">
                Computer engineer and indie gamedev. <br>
                <ul>
                    <li>Experience in most engines: UE4, Unity, GameMaker & Godot.</li>
                    <li>Successful hobbyist game jam developer.</li>
                    <li>Passionate about discussing games and game design.</li>
                    <li>Active online in multiple indie development communities, mostly on Twitter and Discord.</li>
                </ul>
                Aware of most of the indie gaming cosmos, knowledgeable about trends and indie games and the discussions that are happening in the industry both about game design and other issues.
                Always glad to work on diverse and engaged creative teams and share and receive feedback.
            </div>
        </div>

        <div class="title2-row">
          <div class="icon">videogame_asset</div>
          Published games
        </div>

      <div class="leftpad">

        <div class="title3">
          Constellations: Puzzles in the Sky
        </div>
        <div class="date">
          2022-05-25
        </div>

        <div class="leftpad">
          Minimalist puzzle game about connecting numbered stars, published on Steam.
          <br>
          Slowly developed in a 2-person team (Icedrop Games) during non-work hours. - Design, art, programming.
        </div>

      </div>

      <div class="leftpad">

        <div class="title3">
          Terracotta
        </div>
        <div class="date">
          2022-11-14
        </div>

        <div class="leftpad">
          Puzzle action adventure inspired by the terracotta warriors of the Qin dynasty.
          <br>
          In studio Appnormals. - Gameplay, technical art, pixelart.
        </div>

      </div>

        <div class="title2-row">
            <div class="icon">games</div>
            Gamedev experience
        </div>

        <div class="leftpad">

          <div class="title3">
            Teacher at La Salle Campus Barcelona & ENTI
          </div>
          <div class="date">
            2022-...
          </div>

          <div class="leftpad">
            Teaching four university-level courses:
            <ul>
              <li>Video Game Programming (C++, OpenGL)
              </li>
              <li>Object-Oriented Programming
              </li>
              <li>Project Development
              </li>
              <li>Pixelart for videogames
              </li>
            </ul>
          </div>

            <div class="title3">
                Game Designer & Pixel Artist at Appnormals
            </div>
            <div class="date">
                2021-2023
            </div>

            <div class="leftpad">
                Generalist in a small indie studio working on studio's original titles, Frank&Drake and Terracotta.
                <ul>
                    <li>Design and QA for both games
                    </li>
                    <li>Pixel art for Terracotta
                    </li>
                    <li>Game development in Unity
                    </li>
                </ul>
            </div>

          <div class="html2pdf__page-break"/>

            <div class="title3">
                Game Designer at Abylight
            </div>
            <div class="date">
                2020-2020
            </div>

            <div class="leftpad">
                Work on Prison Tycoon and other unannounced projects:
                <ul>
                    <li>Designing mechanics and systems
                    </li>
                    <li>Prototyping
                    </li>
                    <li>Producing and managing content for the games
                    </li>
                </ul>
            </div>



            <div class="title3">
                Participant in lots of game jams
            </div>
            <div class="date">
                2017-...
            </div>

            <div class="leftpad">
                Participant and winner of lots of game jams, both in-person and online, solo and in a team.
                <ul>
                    <li>12th place in Ludum dare 41
                    </li>
                    <li>Itch.io profile with >4.200 downloads and 28.000 views
                    </li>
                    <li>Active member in lots of gamedev and game jam internet communities
                    </li>
                </ul>
            </div>

            <div class="title3">
                Gamedev NPO member
            </div>
            <div class="date">
                2017-2019
            </div>

            <div class="leftpad">
                Member of the council of volunteers that manage the Alakajam website, a non-profit gamedev community that organises regular jams.
            </div>

        </div>

        <div class="title2-row">
            <div class="icon">image</div>
            Portfolio
        </div>

        <div class="leftpad">
            <p>Website: raindrinker.com
            </p>
            <p>itch.io: https://raindrinker.itch.io/
            </p>
            <p>Github: https://github.com/Raindrinker
            </p>
            <p>Twitter: https://twitter.com/Raindrinker_dev
            </p>
        </div>

        <div class="title2-row">
            <div class="icon">school</div>
            Education
        </div>

        <div class="leftpad">

            <div class="title3">
                Master in Advanced Videogame Development, La Salle Campus Barcelona
            </div>
            <div class="date">
                2018-2019
            </div>

            <div class="leftpad">
                Master in La Salle which covers technical skills for developing gamedev engines, working in UE4 and Unity, and other aspects of professional videogame development.
            </div>

            <div class="title3">
                Degree in Computer Engineering + Multimedia Engineering, La Salle Campus Barcelona
            </div>
            <div class="date">
                2013-2018
            </div>

            <div class="leftpad">
                Double degree in La Salle which allows students to finish university studies in 5 years with two official Bachelor's Degrees. This double degree merges the creative and design side with deep knowledge about programming and computer architecture.
                Best academic expedient for both courses.
            </div>

        </div>

        <div class="title2-row">
            <div class="icon">work</div>
            Other work
        </div>

        <div class="leftpad">

            <div class="title3">
                Junior Frontend Sofware Developer at Verbio, Barcelona
            </div>
            <div class="date">
                2018-2020
            </div>

            <div class="leftpad">
                Working in a team specialized in Natural Language Understanding and bot development
                <ul>
                    <li>
                        Designing and developing web tools and dashboards to manage NLU applications
                    </li>
                    <li>
                        Frontend programming and design in Vue
                    </li>
                    <li>
                        Agile software development
                    </li>
                </ul>
            </div>

            <div class="title3">
                Intern Associate at La Salle Campus Barcelona
            </div>
            <div class="date">
                2015-2018
            </div>

            <div class="leftpad">
                Working as an associate teacher alongside the teachers to support in two college courses:
                <ul>
                    <li>
                        Introduction to programming
                    </li>
                    <li>
                        Digital systems and microprocessors
                    </li>
                </ul>
            </div>

        </div>

        <div class="title2-row">
            <div class="icon">chat_bubble</div>
            Contact
        </div>

        <div class="leftpad">
            <p>Discord: Raindrinker#2532
            </p>
            <p>Email: iamtheraindrinker@gmail.com
            </p>
            <p>Phone: 636039184
            </p>
        </div>

        <div class="title2-row">
            <div class="icon">language</div>
            Languages
        </div>

        <div class="leftpad">
            <p>English: Very good
            </p>
            <p>Spanish: Native
            </p>
            <p>Catalan: Native
            </p>
        </div>

    </div>
</template>

<script>
    export default {
        name: "ResumeDocument"
    }
</script>

<style scoped>
    .document {
        background-color: white;
        max-width: 80%;
        width: 100%;
        padding: 64px;
        font-family: Nunito, sans-serif;
    }

    .title1 {
        font-size: 28px;
        font-weight: bold;
    }

    .title2-row {
        display: flex;
        font-size: 24px;
        padding-top: 32px;
        margin-bottom: 8px;
    }

    .icon {
        font-family: 'Material Icons';
        font-size: 32px;
        margin-right: 8px;
    }

    .title3 {
        font-weight: bold;
        margin-top: 16px;
    }

    .date {
        color: #666666;
        margin-bottom: 16px;
    }

    .leftpad {
        padding-left: 16px;
    }
</style>